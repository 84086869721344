<video
  #target
  class="video-js"
></video>

<ng-container *ngIf="!videoError">
  <div class="video-navigation">
    <ros-button
      tabindex="1"
      class="video-navigation__button"
      width="Short"
      variant="Ternary"
      iconPosition="Left"
      icon="ChevronRight"
      [iconNoSeparator]="true"
      type="Info"
      class="video-navigation__button"
      (click)="prevEvent()"
    >
    </ros-button>
    <ros-button
      tabindex="2"
      class="video-navigation__button"
      variant="Ternary"
      iconPosition="Right"
      width="Short"
      [iconNoSeparator]="true"
      icon="ChevronRight"
      type="Info"
      class="video-navigation__button"
      (click)="nextEvent()"
    >
    </ros-button>
  </div>

  <div class="video-timeline-header">
    <span class="video-timeline-header__title">
      Session Timeline
    </span>

    <span *ngIf="(currentTime || currentTime === 0) && (duration || duration === 0)" class="video-timeline-header__time">
        {{currentTime | timeFormatter}} of {{duration | timeFormatter}}
    </span>
  </div>
</ng-container>

<div  *ngIf="videoError" class="video-placeholder-bg">
  <div class="video-placeholder-svg-backdrop">
    <div class="no-video">
      <img
        src="assets/img/proctoring/no_user_video.svg"
        class="no-video__img"
      >
      <span class="no-video__header">
       {{videoErrorTitle}}
      </span>
      <span class="no-video__subtext">
        {{videoErrorSubtitle}}
      </span>
    </div>
  </div>
</div>
