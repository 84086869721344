<div class="id-check">
  <div class="id-check__photo">
    <img
      [src]="learnerPhotoUrl || learnerPhotoPlaceholder"
      alt="Learner photo"
    >
  </div>
  <div class="id-check__photo">
    <img
      [src]="learnerIDPhotoUrl || learnerIDPhotoPlaceholder"
      alt="Learner ID photo"
    >
  </div>
</div>
