import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-id-check',
  templateUrl: './id-check.component.html',
  styleUrls: ['./id-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCheckComponent {
  @Input() public firstName: string;
  @Input() public lastName: string;
  @Input() public learnerPhotoUrl: string;
  @Input() public learnerIDPhotoUrl: string;

  public learnerPhotoPlaceholder = '/assets/img/proctoring/learner-photo-placeholder.jpg'
  public learnerIDPhotoPlaceholder = '/assets/img/proctoring/learner-id-placeholder.jpg';
}
