import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MaterialModule } from 'app/modules/material/material.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouterModule } from '@angular/router';
import { MatInputPercentageDirective } from '@modules/shared/directives/matInputPercentage.directive';
import { ContentScrollRetainerDirective } from '@modules/shared/directives/contentScrollRetainer.directive';
import { SecondsToDescriptionPipe } from '@modules/shared/pipes/seconds-to-description.pipe';
import { PricePipe } from '@modules/shared/pipes/price-formatter.pipe';
import { PendoService } from '@modules/shared/services/analytics/pendo.service';
import { ScriptInjectorService } from '@modules/shared/services/scripts/script-injector.service';
import { RosalynComponentsModule } from 'rosalyn-components';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperDialogService } from './components/image-cropper-dialog/image-cropper-dialog.service';
import { TimeFormatterPipe } from './pipes/time-formatter.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MyAssessmentsCardComponent } from './components/my-assessments-card/my-assessments-card.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { RolesPipe } from './pipes/roles.pipe';
import { BasicSnackBarService } from './services/basic-snackbar.service';
import { SeverityIconComponent } from './components/severity-icon/severity-icon.component';
import { HintComponent } from './components/hint/hint.component';
import { IdCheckComponent } from './components/id-check/id-check.component';
import { PageUnderConstructionComponent } from './components/page-under-construction/page-under-construction.component';
import { BasicDialogComponent } from './components/basic-dialog/basic-dialog.component';
import { SessionStatusComponent } from './components/session-status/session-status.component';
import { FullStoryService } from './services/analytics/fullstory.service';
import { TooltipIconComponent } from './components/tooltip-icon/tooltip-icon.component';
import { SeverityComponent } from './components/severity/severity.component';
import { SessionStatusDotComponent } from './components/session-status-dot/session-status-dot.component';
import { VideoPlayerCustomComponent } from './components/video-player-custom/video-player.component';
import { EventStatusComponent } from './components/event-status/event-status.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { SessionTimeDataComponent } from './components/session-time-data/session-time-data.component';
import { IncompleteSessionBanner } from './components/incomplete-session-banner/incomplete-session-banner.component';
import { SpinnerContainer } from './components/spinner-container/spinner-container.component';
import { BasicErrorDialogComponent } from './components/error-dialog/basic-error-dialog.component';
import { ExamSettingsComponent } from './components/exam-settings/exam-settings.component';

@NgModule({
  declarations: [
    PasswordStrengthComponent,
    ImageCropperDialogComponent,
    IncompleteSessionBanner,
    TimeFormatterPipe,
    SecondsToDescriptionPipe,
    PricePipe,
    MatInputPercentageDirective,
    ContentScrollRetainerDirective,
    RolesPipe,
    OrdinalPipe,
    ConfirmDialogComponent,
    MyAssessmentsCardComponent,
    VideoPlayerComponent,
    VideoPlayerCustomComponent,
    SeverityIconComponent,
    SeverityComponent,
    HintComponent,
    IdCheckComponent,
    PageUnderConstructionComponent,
    BasicDialogComponent,
    BasicErrorDialogComponent,
    SessionStatusComponent,
    SessionStatusDotComponent,
    SessionTimeDataComponent,
    SpinnerContainer,
    EventStatusComponent,
    TooltipIconComponent,
    ClickOutsideDirective,
    ExamSettingsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ImageCropperModule,
    RouterModule,
    RosalynComponentsModule,
  ],
  exports: [
    MatInputPercentageDirective,
    ContentScrollRetainerDirective,
    PasswordStrengthComponent,
    MyAssessmentsCardComponent,
    VideoPlayerComponent,
    VideoPlayerCustomComponent,
    TimeFormatterPipe,
    SecondsToDescriptionPipe,
    PricePipe,
    RolesPipe,
    OrdinalPipe,
    SeverityIconComponent,
    SeverityComponent,
    HintComponent,
    IdCheckComponent,
    IncompleteSessionBanner,
    BasicDialogComponent,
    BasicErrorDialogComponent,
    SessionStatusComponent,
    SessionStatusDotComponent,
    EventStatusComponent,
    TooltipIconComponent,
    ClickOutsideDirective,
    SessionTimeDataComponent,
    SpinnerContainer,
    ExamSettingsComponent,
  ],
  providers: [
    ImageCropperDialogService,
    BasicSnackBarService,
    CurrencyPipe,
    ScriptInjectorService,
    FullStoryService,
    PendoService,
  ],
})
export class SharedModule { }
